import React, { useState, useMemo, useEffect } from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import {Editor} from "react-draft-wysiwyg"
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { toast } from 'react-toastify';
import Api from '../services/Api'
import camera from '../assets/icons/camera.svg';


const ListItem = ({ id, catequeseId, item, name, videosId, description, content, categorias, thumbnail, active, onDelete, loadItem, setLoading }) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [stateName, setStateName] = useState(name);
  const [stateDescription, setStateDescription] = useState(description);
  const [stateThumbnail, setStateThumbnail] = useState(thumbnail);
  const [stateContent, setStateContent] = useState(EditorState.createEmpty());
  const [stateActive, setStateActive] = useState(active);
  const [idYoutubeSelected, setIdYoutubeSelected] = useState(videosId);
  const [stateIdYoutube, setStateIdYoutube] = useState('');

  useEffect(() => {
    if (toHtml(stateContent) === content) return //added
    setStateContent(
      EditorState.push(
        stateContent,
        ContentState.createFromBlockArray(
          htmlToDraft(content || ''),
        ),
      ),
    )
  }, [content]);

  function onEditorStateChange(es) {
    setStateContent(es)
    const html = toHtml(es) //added
    if (content !== html) {
      //() => onChange({ target: { name: 'text', eventContent: html } })
    }
  }

  function toHtml(es) {
    return draftToHtml(convertToRaw(es.getCurrentContent())) // added
  }

  // useMemo: executa quando determinado estado for alterado, neste caso o estado 'stateThumbnail'
  const preview = useMemo(() => {
    if(thumbnail !== "" && thumbnail === stateThumbnail){
      return Api.BASE_URL + '/files/'+ thumbnail;
    } else {
      return stateThumbnail ? URL.createObjectURL(stateThumbnail) : null;
    }
  }, [stateThumbnail]);
  
  function onChangeThumbnailFile(e){
    if(e.target.files[0]){
      let file_size = e.target.files[0].size;
      //let file_name = e.target.files[0].name;
      //let file_type = e.target.files[0].type;
      //console.log('executou o file change: ' +  file_size);
      //Se maior que um 1mb(1048576)
      if(parseInt(file_size) >= 1048000) {
        toast.error("Imagem não suportada! A imagem deve ter no máximo 1MB", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }else{
        setStateThumbnail(e.target.files[0])
      }
    }
  };

  async function handleSubmit(e){
    e.preventDefault();
    if(stateName !== "" && stateContent !== ""){
      setLoading(true);
      try {
        var route = "", data;
        if(item === "Aula"){
          route = "/store-aula";
          data = {
            "id": id,
            "name": stateName,
            "catequese": catequeseId,
            "videosId": idYoutubeSelected,
            "content": toHtml(stateContent),
          }
        } else if(item === "Event"){
          route = "/store-event";
          data = new FormData();
          data.append('id', id);
          data.append('name', stateName);
          if(typeof stateThumbnail === "object" && thumbnail !== stateThumbnail.name){
            data.append('thumbnail', stateThumbnail);
          }
          data.append('description', stateDescription);
          data.append('content', toHtml(stateContent));
        }
        const response = await Api.api.post(route, data);
        console.log(response);
        if(response.data.hasOwnProperty('_id') || response.data.hasOwnProperty('ok')){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setToggleItem([]);
          const reloadItem = loadItem;
          reloadItem();
          setLoading(false);
          if(item === "Event"){
            if(Number.isInteger(id)){
              console.log("Envia notificação");
              Api.sendNotification(stateName, stateDescription, '*', '*');
            }
          }
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("(*) Preencha todos os campos obrigatórios!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  function selectIdYoutube(idYoutube){
    var arrIdYoutubeSelect = idYoutubeSelected;
    if(arrIdYoutubeSelect.indexOf(idYoutube) === -1){
      arrIdYoutubeSelect.push(idYoutube);
      setIdYoutubeSelected(arrIdYoutubeSelect);    
      setStateIdYoutube("");
    }
  } 

  function removeIdYoutube(idYoutube){
    var arrIdYoutubeSelect = idYoutubeSelected;
    var index = arrIdYoutubeSelect.indexOf(idYoutube);
    arrIdYoutubeSelect.splice(index, 1);
    setIdYoutubeSelected(arrIdYoutubeSelect);
    setStateIdYoutube(idYoutube);
  }

  //style={active || stateActive ? {} : {opacity: 0.4}}
  return (
      <li className={toggleItem[0] === id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList"}>
        <div className="row headList" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>
          <div className="col-10">
            <b>{stateName}</b>
          </div>
          <div className="col-2 text-right">
            <span className={`iconItem ${toggleItem[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
          </div>
        </div>
        {toggleItem[0] === id ?
          <div className="row">
            <div className="col-12">
              <hr/>
              <form onSubmit={handleSubmit}>
                <div className="row v-center">
                  <div className="col-12">
                    <div className="row">
                      <div className="form-group col-12">
                        {/*<label className="switch itemDashboardListSwitch">
                          <input type="checkbox" value={stateActive} checked={stateActive} onChange={() => setStateActive(!stateActive)} />
                          <div className="slider"></div>
                        </label>*/}
                        {item === "Event" ?
                        <>
                          <label className="labelImage">Imagem:</label>
                          <label id="thumbnail" style={{ backgroundImage: `url(${preview})` }} className={stateThumbnail ? 'has-thumbnail' : ''}>
                            <input type="file" onChange={onChangeThumbnailFile} />
                            <img src={camera} alt="Select img" />
                          </label>
                        </>
                        : <></>} 
                      </div>
                      <div className="form-group col-12">
                        <label className="labelInput">Nome: *</label>
                        <input 
                          type="text" 
                          className="form-control" 
                          placeholder="Nome"
                          value={stateName}
                          onChange={event => setStateName(event.target.value)}
                        />
                      </div>
                      {item === "Event" ?
                        <div className="form-group col-12">
                          <label className="labelInput">Descrição: *</label>
                          <textarea className="form-control" maxLength="177" value={stateDescription} onChange={event => setStateDescription(event.target.value)} />
                        </div>
                      : item === "Aula" ?
                        <div className="form-group col-12">
                          <label className="labelInput">ID vídeo(s) Youtube: </label>
                          <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Insira o id do vídeo do Youtube"
                            value={stateIdYoutube}
                            onChange={event => setStateIdYoutube(event.target.value)}
                            //onFocus={() => setStateIdYoutube("")}
                            //onBlurCapture={event => selectIdYoutube(event.target.value)}
                          />
                          <button type="button" className="btn btn-default btn-input" onClick={() => selectIdYoutube(stateIdYoutube)}>Adicionar</button>
                          <div className="row" style={{marginLeft: -5, marginTop: 5, marginBottom: -8}}>
                            {idYoutubeSelected.map((item, index) =>
                              <div key={index} className="idYoutubeSelected">
                                <a href={"https://www.youtube.com/embed/"+item} target="_Blank"><span>{"https://www.youtube.com/embed/"+item}</span></a>
                                <span onClick={() => removeIdYoutube(item)} className="btnDeleteIdYoutube">
                                  <span className="fas fa-times"></span>
                                </span>
                              </div>
                            )}         
                          </div>
                        </div>
                      : <></> }
                      <div className="form-group col-12">
                        <Editor 
                          editorState={stateContent}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="contentEditor"
                          editorClassName="inputEditor"
                          onEditorStateChange={onEditorStateChange}
                        />
                      </div>
                    </div>     
                  </div>     
                      
                  <div className="col-12 text-right">
                    <button type="button" className="btn btn-delete" onClick={onDelete}> Excluir </button>
                    <span> </span>
                    <button type="submit" className="btn btn-default"> Salvar </button>
                  </div>
                </div>
              </form>
                
            </div>
          </div>
          : '' }
      </li>
  );
};

export default ListItem;
