//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState} from 'react';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import ListItemAudio from '../../components/ListItemAudio'
import './styles.css';
import '../../App.css';

export default function DashboardAudios({setLoading}) {
  const [audios, setAudios] = useState([]);

 // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadAudios();
  }, []);

  async function loadAudios() {
    try {
      const response = await Api.getAudios();
      setAudios(response);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  async function addAudio(){
    const audioCopy = audios ? Array.from(audios) : [];
    console.log(audioCopy);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    audioCopy.unshift({
      _id: audioCopy.length +1,
      name: "",
      audio: "",
      //description: "",
      //category: "",
      //thumbnail: "",
      //background: "",
      //date: new Date().toISOString().substr(0,10),
    });
    await setAudios(audioCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(audioCopy.length)
  }

  async function removeAudio(id){
    setLoading(true);
    const audioCopy = Array.from(audios);
    var index = audioCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.api.post('/delete-audio', {'id': id});
        console.log(response);
        if(Number(response.data.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          audioCopy.splice(index, 1);
          setAudios(audioCopy);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      audioCopy.splice(index, 1);
      setAudios(audioCopy);
    }
    setLoading(false);
  }

  console.log(audios);
  //const childRef = useRef();
  return (
    <div id="audiosList" className="bodyDashboard">
      <div className="v-center">
        <div className="col-4">
          <span className="title">Áudios</span>
        </div>
        <div className="col-4 text-center">
        </div>
        <div className="col-4 text-right">
          {/*<button type="button" onClick={addAudio} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>*/}
        </div>
      </div>
        <div className="col-12">
          <ul className="dashboardList">
            {
            audios.length > 0 ?
            audios.map(item => (
              <ListItemAudio
                key={item._id}
                id={item._id}
                name={item.name}
                audio={item.audio} 
                //audioDescription={item.description}
                //audioCategory={item.category}
                //audioThumbnail={item.thumbnail} 
                //audioBackground={item.background}
                //audioDate={item.date.substring(0,10)}
                onDelete={() => removeAudio(item._id)}
                loadAudios={() => loadAudios()}
                setLoading={setLoading}
                //ref={childRef}
              />
            )) :
            <div className="col-12 text-center">
              <span className="labelFilter">Nenhum áudio encontrado!</span>
            </div>
          }
          </ul>
      </div>
    </div>
  )
 
}