//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState} from 'react';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import ListItemUser from '../../components/ListItemUser'
import './styles.css';
import '../../App.css';

export default function DashboardUsers({setLoading}) {
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState("");

 // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadUsers();
  }, []);

  useEffect(() => {
    onSearch();
  }, [search]);

  async function loadUsers() {
    try {
      const response = await Api.getUsers();
      setUsers(response);
      setAllUsers(response);
      loadCategories();
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  async function loadCategories() {
    var response= await Api.getCatequeses();
    setCategories(response);
    setLoading(false);
  }

  async function addUser(){
    const userCopy = Array.from(users);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    userCopy.unshift({
      _id: userCopy.length +1,
      name: "",
      email: "",
      telefone: "",
      dataNascimento: "",
      dateCreated: "",
      catequeseList: [],
      comunidade: "",
      token: "",
      admin: "",
      active: true,
      //date: new Date().toISOString().substr(0,10),
    });
    await setUsers(userCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(userCopy.length)
  }

  async function removeUser(id){
    setLoading(true);
    const userCopy = Array.from(users);
    var index = userCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.api.post('/delete-user', {'id': id});
        console.log(response);
        if(Number(response.data.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          userCopy.splice(index, 1);
          setUsers(userCopy);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      userCopy.splice(index, 1);
      setUsers(userCopy);
    }
    setLoading(false);
  }

  function onSearch(){
    if(search !== ""){
      var arr = [...allUsers];
      var result = arr.filter(function(item){
        return item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 
        || item.email.toLowerCase().indexOf(search.toLowerCase()) !== -1
      });
      setUsers(result);
    } else {
      setUsers([]);
      loadUsers();
    }
  }

  //console.log(users);
  //const childRef = useRef();
  return (
    <div id="userList" className="bodyDashboard">
      <div className="v-center">
        <div className="col-3">
          <span className="title">Usuários</span>
        </div>
        <div className="col-6 text-right">
          <input
            autoFocus
            type="text" 
            className="form-control" 
            placeholder="Pesquisar usuário"
            style={{height: "auto", borderRadius: 30, padding: 15}}
            value={search}
            onChange={event => setSearch(event.target.value)}
          />
          <i className="fa fa-search fa-lg iconSearchInput"></i>
        </div>
        <div className="col-3 text-right">
          <button type="button" onClick={addUser} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>
        </div>
      </div>
      <br />
      <div className="col-12">
          <ul className="dashboardList">
            {users.length > 0 ?
            users.map(item => (
              <ListItemUser
                key={item._id}
                id={item._id}
                name={item.name}
                email={item.email}
                telefone={item.telefone}
                dataNascimento={item.dataNascimento} 
                dateCreated= {item.dateCreated}
                catequeseList={item.catequeseList} 
                comunidade={item.comunidade}
                token={item.hasOwnProperty('token') ? item.token : ''}
                admin={item.admin}
                active={item.active}
                categories= {categories}
                onDelete={() => removeUser(item._id)}
                loadUsers={() => loadUsers()}
                setLoading={setLoading}
                //ref={childRef}
              />
            )) :
            <div className="col-12 text-center">
              <span className="labelFilter">Nenhum usuário encontrado!</span>
            </div>}
          </ul>
      </div>
    </div>
  )
 
}