import React, { useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import Api from '../services/Api'
import camera from '../assets/icons/camera.svg';

const ListItemUser = ({ id, name, email, telefone, dataNascimento, dateCreated, catequeseList, comunidade, admin, token, active, categories, onDelete, loadUsers, setLoading }) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [StateUserName, setStateUserName] = useState(name);
  const [StateUserComunidade, setStateUserComunidade] = useState(comunidade);
  const [StateUserEmail, setStateUserEmail] = useState(email);
  const [stateTelefone, setStateTelefone] = useState(telefone);
  const [stateDataNascimento, setStateDataNascimento] = useState(dataNascimento?.split("/").reverse().join('-'));
  const [stateCatequeseList, setStateCatequeseList] = useState(catequeseList);
  const [stateActive, setStateActive] = useState(active);
  const [stateAdmin, setStateAdmin] = useState(admin);
  const comunidades = ['Matriz', 'Comunidade Bairro Esperança', 'Comunidade Católica São Luís', 'Comunidade Cristo Rei', 'Comunidade Espírito Santo', 'Comunidade de São Caetano', 'Comunidade de São Paulo Caneleiras', 'Comunidade de São Roque', 'Comunidade Mãe de Cristo', 'Comunidade Nossa Senhora do Caravágio', 'Comunidade Nossa Senhora Aparecida', 'Comunidade Nossa Senhora da Glória', 'Comunidade Nossa Senhora da Saúde', 'Comunidade Nossa Senhora da Consolata', 'Comunidade Nossa Senhora das Dores', 'Comunidade Nossa Senhora das Graças', 'Comunidade Nossa Senhora do Carmo', 'Comunidade Nossa Senhora do Rosário', 'Comunidade Nossa Senhora de Medianeira', 'Comunidade Nossa Senhora de Lourdes', 'Comunidade Nossa Senhora de Fátima', 'Comunidade Nossa Senhora de Schoenstatt', 'Comunidade Nossa Senhora dos Navegantes', 'Comunidade Nossa Senhora do Bom Conselho', 'Comunidade Sagrado Coração de Jesus', 'Comunidade Sagrada Família', 'Comunidade Santíssima Trindade', 'Comunidade Santa Lúcia', 'Comunidade Santa Maria', 'Comunidade Santa Rita', 'Comunidade Santa Teresinha', 'Comunidade Santa Tereza Verzéri', 'Comunidade Santo Antônio', 'Comunidade Santo Antão', 'Comunidade Santo Anjo da Guarda', 'Comunidade São Brás', 'Comunidade São Francisco', 'Comunidade São Pedro de Mato Queimado', 'Comunidade São José Anchieta', 'Comunidade São João Batista', 'Comunidade São José', 'Comunidade São Judas Tadeu', 'Comunidade São Lucas', 'Comunidade São Marcos', 'Comunidade São Pedro Apóstolo', 'Comunidade São Tiago']
  const isNewUser = !id.toString().match(/^[0-9a-fA-F]{24}$/);

  function onChangeCategory(idCatequese){
    var arrCatequeseList = [...stateCatequeseList]
    console.log(arrCatequeseList);
    if(arrCatequeseList.indexOf(idCatequese+"") === -1){
      arrCatequeseList.push(idCatequese);
    } else {
      arrCatequeseList.splice(arrCatequeseList.indexOf(idCatequese+""), 1);
    }
    setStateCatequeseList(arrCatequeseList);
  }

  async function handleSubmit(e){
    e.preventDefault();
    setLoading(true);
    try {
      var route = "/update-user";
      var body = {
        id: id,
        name: StateUserName,
        email: StateUserEmail.toLowerCase(),
        telefone: stateTelefone,
        dataNascimento: stateDataNascimento?.split("-").reverse().join('/'), 
        comunidade: StateUserComunidade,     
        active: stateActive,
        admin: stateAdmin,
        catequeseList: stateCatequeseList,
        updateStatusUser: true
      }
      if(isNewUser) {
        route = "/store-user";
        body.updateStatusUser = undefined;
        body.password = StateUserEmail.toLowerCase();
      }
      const response = await Api.api.post(route, body);
      if(response?.data?.ok === 1 || response?.data?._id){
        toast.success("Operação realizada com sucesso!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setToggleItem([]);
        const reloadUsers = loadUsers;
        reloadUsers();
        if(!Number.isInteger(id) && token && token !== ""){
          if(!active && stateActive){
            console.log("Envia notificação");
            Api.sendNotification("Cadastro Aprovado!", 'Seu cadastro foi aprovado, agora você pode conferir os conteúdos da catequese! Acesse o app e confira!', "*", token);
          }
        }
        setLoading(false);
      } else {
        toast.error("Falha ao realizar a operação!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  return (
      <li className={toggleItem[0] === id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList"}>
        <div className="row headList" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>
          <div className="col-10">
            <span>{StateUserName + ' - ' + StateUserEmail} </span>
          </div>
          <div className="col-2 text-right">
            <span className={`iconItem ${!active ? 'fas fa-exclamation-triangle fa-lg ' : ''}`} style={{color: 'rgb(255 189 3)'}}></span>
            <span className={`iconItem ${toggleItem[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
          </div>
        </div>
        {toggleItem[0] === id ?
          <div className="row">
            <div className="col-12">
              <hr/>
              <form onSubmit={handleSubmit}>
                <div className="row v-center">
                  <div className="col-12">
                    <label className="switch itemDashboardListSwitch">
                      <input type="checkbox" value={stateActive} checked={stateActive} onChange={() => setStateActive(!stateActive)} />
                      <div className="slider"></div>
                    </label>
                    <div className="row">
                      <div className="col-12">
                        <label className="">Informações:</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-12">
                        <label className="labelInput">Nome *:</label>
                        <input 
                          required
                          type="text" 
                          className="form-control" 
                          name="name"
                          placeholder="Nome do Usuário"
                          value={StateUserName}
                          onChange={event => setStateUserName(event.target.value)}
                        />
                      </div>  
                      <div className="form-group col-12">
                        <label className="labelInput">Email *:</label>
                        <input 
                          required
                          type="text" 
                          className="form-control" 
                          placeholder="Nome da Usuário"
                          value={StateUserEmail}
                          onChange={event => setStateUserEmail(event.target.value)}
                        />
                      </div>
                      <div className="form-group col-12 col-md-6">
                        <label className="labelInput">Telefone:</label>
                        <input 
                          type="text" 
                          className="form-control" 
                          placeholder="Telefone do Usuário"
                          value={stateTelefone}
                          onChange={event => setStateTelefone(event.target.value)}
                        />
                      </div>  
                      <div className="form-group col-12 col-md-6">
                        <label className="labelInput">Data Nascimento:</label>
                        <input 
                          type="date" 
                          className="form-control" 
                          placeholder="Data Nascimento"
                          value={stateDataNascimento}
                          onChange={event => setStateDataNascimento(event.target.value)}
                        />
                      </div>
                      <div className="form-group col-12">
                        <label className="labelInput">Comunidade:</label>
                        <select className="form-control" onChange={event => setStateUserComunidade(event.target.value)}>
                          <option value={StateUserComunidade}>{StateUserComunidade}</option>
                          {comunidades.map((item) => (<option key={item} value={item}>{item}</option>))}
                        </select>
                      </div>
                      <div className="form-group col-12">
                        <label className="labelInput">Usuário Admin:</label>
                        <select className="form-control" value={stateAdmin} onChange={event => setStateAdmin(event.target.value)}>
                          <option value={false}>Não</option>
                          <option value={true}>Sim</option>
                        </select>
                      </div> 
                      {/*<div className="form-group col-12">
                        <div className="row" style={{marginLeft:5}}>
                          <div className="form-group col-12 col-md-6">
                            <div>Nome: <label>{StateUserName}</label></div>
                            <div>Telefone: <label>{
                              stateTelefone && stateTelefone.length > 0 ?
                                stateTelefone.substr(5).charAt(0) === "9" ?
                                  <a href={"https://wa.me/55"+stateTelefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", "")} target="_Blank">{stateTelefone} <span className="fab fa-whatsapp fa-lg" style={{marginLeft: 3, color: "green"}}></span></a>
                                : stateTelefone
                              : ""
                            }</label></div>
                            <div>Comunidade: <label>{StateUserComunidade}</label></div>
                          </div>  
                          <div className="form-group col-12 col-md-6">
                            <div>Email: <label>{StateUserEmail}</label></div>
                            <div>Data Nascimento: <label>{stateDataNascimento ? stateDataNascimento.substr(0,10).split("-").reverse().join('/') : ''}</label></div>
                            <div>Status do Cadastro: <label>{active ? 'Ativo' : 'Aguardando Ativação'}</label></div>
                            {/*<div>Data Cadastro: <label>{new Date(stateDateCreated).toLocaleDateString()}</label></div>*c}
                          </div>  
                        </div>  
                      </div> */}
                      <div className="form-group col-12">
                        <label className="">Formações Selecionadas:</label>
                        <div className="categoryListBody">
                          {categories.map((item) => {
                            return (
                              <div key={item._id} className={stateCatequeseList.indexOf(item._id+"") === -1 ? "categoryItemListBody v-center" : "categoryItemListBody categoryItemListBodyActive v-center"} onClick={() => onChangeCategory(item._id)}>
                                <div className={stateCatequeseList.indexOf(item._id+"") === -1 ? "categoryItemIconistBody far fa-square fa-2x" : "categoryItemIconistBodyActive fa fa-check-circle fa-2x"}></div>
                                <div className={stateCatequeseList.indexOf(item._id+"") === -1 ? "categoryItemTextListBody" : "categoryItemTextListBody categoryItemTextListBodyActive"}>{item.name}</div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      {isNewUser && 
                        <div className="form-group col-12">
                          <label className="">* Atenção: A SENHA de acesso ao APP é o EMAIL!</label>
                        </div>
                      }
                    </div>     
                  </div>     
                  <div className="col-12">
                    <hr /> 
                  </div>
                  <div className="col-12 text-right">
                    <button type="button" className="btn btn-delete" onClick={onDelete}>Excluir</button>
                    <span> </span>
                    <button type="submit" className="btn btn-default">Salvar</button>
                  </div>
                </div>
              </form>
                
            </div>
          </div>
          : '' }
      </li>
  );
};

export default ListItemUser;
