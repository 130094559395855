import React from 'react';
const Functions = {
    checkMonth: function (month){
      var arrMonth=new Array();
      arrMonth[1]='Jan';
      arrMonth[2]='Fev';
      arrMonth[3]='Mar';
      arrMonth[4]='Abr';
      arrMonth[5]='Mai';
      arrMonth[6]='Jun';
      arrMonth[7]='Jul';
      arrMonth[8]='Ago';
      arrMonth[9]='Set';
      arrMonth[10]='Out';
      arrMonth[11]='Nov';
      arrMonth[12]='Dez';
      return arrMonth[parseInt(month)];
    }
}
export default Functions;
  