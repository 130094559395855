//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState} from 'react';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import ListItem from '../../components/ListItem'
import './styles.css';
import '../../App.css';

export default function DashboardEvents({setLoading}) {
  const [events, setEvents] = useState([]);

 // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadEvents();
  }, []);

  async function loadEvents() {
    try {
      const response = await Api.getEvents();
      setEvents(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function addEvent(){
    //new Date().getTime(),
    const eventsCopy = Array.from(events);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    eventsCopy.unshift({
      _id: (eventsCopy.length +1) + "",
      item: "Event",
      name: "",
      description: "",
      content: "",
      thumbnail: "",
      //active: true
    });
    console.log(eventsCopy);
    await setEvents(eventsCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(eventsCopy.length)
  }

  async function removeEvent(id){
    setLoading(true);
    const eventsCopy = Array.from(events);
    var index = eventsCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.api.post('/delete-event', {'id': id});
        console.log(response);
        if(Number(response.data.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          eventsCopy.splice(index, 1);
          setEvents(eventsCopy);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      eventsCopy.splice(index, 1);
      setEvents(eventsCopy);
    }
    setLoading(false);
  }

  //console.log(events);
  //const childRef = useRef();
  return (
    <div id="eventsList" className="bodyDashboard">
      <div className="v-center">
        <div className="col-4">
          <span className="title">Eventos</span>
        </div>
        <div className="col-4 text-center">
          {/*<span onClick={() => changeMonth("back")}>
            <span className="fas fa-chevron-circle-left fa-lg iconFilter"></span>
          </span>
          <span className="labelFilter">{monthFilter}</span>
          <span onClick={() => changeMonth("next")}>
            <span className="fas fa-chevron-circle-right fa-lg iconFilter"></span>
          </span>*/}
          </div>
        <div className="col-4 text-right">
          <button type="button" onClick={addEvent} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>
        </div>
      </div>
        <div className="col-12">
          <ul className="dashboardList">
            {
            events.length > 0 ?
            events.map(item => (
              <ListItem
                key={item._id}
                item="Event"
                id={item._id}
                name={item.name}
                description={item.description}
                content={item.content}
                thumbnail={item.thumbnail} 
                //order={item.order} 
                //active={item.active} 
                onDelete={() => removeEvent(item._id)}
                loadItem={() => loadEvents()}
                setLoading={setLoading}
              />
            )) :
            <div className="col-12 text-center">
              <span className="labelFilter">Não há itens cadastrados!</span>
            </div>
          }
          </ul>
      </div>
    </div>
  )
 
}