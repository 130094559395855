//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState} from 'react';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import ListItem from '../../components/ListItem'
import './styles.css';
import '../../App.css';

export default function DashboardCatequese({setLoading}) {
  const [catequese, setCatequese] = useState([]);
  const [aulas, setAulas] = useState([]);
  const [catequeseSelect, setCatequeseSelect] = useState([]);
  const [alunos, setAlunos] = useState([]);
  const [dateFilter, setDateFilter] = useState('');
  const [monthFilter, setMonthFilter] = useState('');

 // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadCatequese();
  }, []);

  /*async function loadDataAtual(){
    /*var dataHoraAtual = await Functions.loadDataAtual();
    var date = new Date(dataHoraAtual);
    var dateStart = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substr(0,10);
    var dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().substr(0,10);
    console.log(dateStart)
    console.log(dateEnd)
    loadCatequese(dateStart, dateEnd);
    setMonthFilter(Functions.checkMonth(date.toISOString().substr(5,2)) + " de " + date.toISOString().substr(0,4));
    setDateFilter(date);
  }*/

  async function loadCatequese() {
    try {
      const response = await Api.getCatequeses();
      setCatequese(response);
      onChangeCatequese(catequeseSelect.hasOwnProperty("id") ? catequeseSelect.id : response[0].id);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function loadAlunosCatequese(idCatequese) {
    try {
      const response = await Api.getAlunosCatequese(idCatequese);
      console.log(response);
      setAlunos(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function onChangeCatequese(idCatequese){
    const responseCatequese = await Api.getCatequese(idCatequese)
    setCatequeseSelect(responseCatequese);
    const responseAulas = await Api.getAulasCatequese(idCatequese)
    setAulas(responseAulas);
    loadAlunosCatequese(idCatequese);
  }

  /*function changeMonth(action){
    var dateStart, dateEnd;
    var dataAtual = new Date(dateFilter);
    if(action === "back"){
      dateStart = new Date(dataAtual.getFullYear(), dataAtual.getMonth() -1, 1).toISOString().substr(0,10);
      dateEnd = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 0).toISOString().substr(0,10);
    } else {
      dateStart = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 1).toISOString().substr(0,10);
      dateEnd = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 2, 0).toISOString().substr(0,10);
    }
    loadCatequese(dateStart, dateEnd);
    setDateFilter(dateEnd);
    setMonthFilter(Functions.checkMonth(dateEnd.substr(5,2)) + " de " + dateEnd.substr(0,4));
    console.log(dateStart);
    console.log(dateEnd);
  }*/

  async function addAula(){
    //new Date().getTime(),
    const aulasCopy = Array.from(aulas);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    aulasCopy.unshift({
      _id: (aulasCopy.length +1) + "",
      item: "Aula",
      name: "",
      videosId: [],
      catequese: catequeseSelect.id,
      content: "",
      //thumbnail: "",
      //active: true
    });
    console.log(aulasCopy);
    await setAulas(aulasCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(aulasCopy.length)
  }

  async function removeAula(id){
    setLoading(true);
    const aulasCopy = Array.from(aulas);
    var index = aulasCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.api.post('/delete-aula', {'id': id});
        console.log(response);
        if(Number(response.data.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          aulasCopy.splice(index, 1);
          setAulas(aulasCopy);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      aulasCopy.splice(index, 1);
      setAulas(aulasCopy);
    }
    setLoading(false);
  }

  function checkProgressComplete(user){
    var aulasComplete = user.hasOwnProperty("aulasComplete") ? user.aulasComplete : [];
    var aulasTotal = Array.from(aulas);
    var qtdAulasComplete = 0, qtdAulas = aulasTotal.length, percentualComplete = 0;
    if(aulasComplete.length > 0){
      aulasComplete.map((itemAulaComplete) => {
        aulasTotal.filter(item => {
          if(item._id === itemAulaComplete){
            return qtdAulasComplete = qtdAulasComplete + 1
          }
        });
      });
      percentualComplete = (qtdAulasComplete * 100) / qtdAulas;
      //console.log('Aulas completadas: ' + qtdAulasComplete);
      //console.log(qtdAulas);
      //console.log(percentualComplete);
      console.log(percentualComplete.toFixed(2));
    }
    return percentualComplete > 0 ? percentualComplete.toFixed(2) : 0;
  }

  //console.log(catequese);
  //const childRef = useRef();
  return (
  <>
    <div id="catequeseList" style={{width: '100%', maxWidth: 750, margin: '0px auto 0'}}>
      <div className="v-center">
        <div className="col-6 col-md-4">
          <select className="title" style={{border: 0, background: 'transparent'}} onChange={event => onChangeCatequese(event.target.value)}>
            <option value={catequeseSelect.id}>{catequeseSelect.name}</option>
            {catequese.map((item) => {
              if(item.id !== catequeseSelect.id){
                return(
                  <option key={item.id} value={item.id}>{item.name}</option>
                );
              }
            })}
          </select>
          {/*<span className="title">Catequese</span>*/}
        </div>
        <div className="col-2 col-md-4 text-center">
          {/*<span onClick={() => changeMonth("back")}>
            <span className="fas fa-chevron-circle-left fa-lg iconFilter"></span>
          </span>
          <span className="labelFilter">{monthFilter}</span>
          <span onClick={() => changeMonth("next")}>
            <span className="fas fa-chevron-circle-right fa-lg iconFilter"></span>
          </span>*/}
          </div>
        <div className="col-4 col-md-4 text-right">
          <button type="button" onClick={addAula} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>
        </div>
      </div>
      <div className="col-12 bodyDashboard" style={{maxHeight: '78vh'}}>
        <ul className="dashboardList">
          {
            aulas.length > 0 ?
            aulas.map(item => (
              <ListItem
                key={item._id}
                item="Aula"
                id={item._id}
                catequeseId={item.catequese}
                name={item.name}
                videosId={item.hasOwnProperty("videosId") ? item.videosId : []}
                content={item.content}
                //thumbnail={item.thumbnail} 
                //order={item.order} 
                //active={item.active} 
                onDelete={() => removeAula(item._id)}
                loadItem={() => loadCatequese()}
                setLoading={setLoading}
                //ref={childRef}
              />
            )) :
            <div className="col-12 text-center">
              <span className="labelFilter">Não há itens cadastrados!</span>
            </div>
          }
        </ul>
      </div>
      <br />
      <div className="col-12 bodyDashboard" style={{maxHeight: '40vh', marginBottom: 15}}>
        <div className="v-center">
          <div className="col-4">
            <span className="title">Alunos</span>
          </div>
          <div className="col-4 text-center">
          </div>
          <div className="col-4 text-right">
            {/*<button type="button" onClick={addUser} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>*/}
          </div>
        </div>
        <div className="card">
        {
          alunos.length > 0 ?
          alunos.map((item) => {
            return (
              <div key={item._id} className="col-12" style={{padding: 8, borderBottom: '1px solid #eee'}}>
                <div className="row">
                  <div className="col-10">
                    <span className="">{item.name}</span>
                  </div>
                  <div className="col-2 text-center">
                    <span className="">{checkProgressComplete(item)}%</span>
                  </div>
                </div>
              </div>
            )
          })
        :
          <div className="col-12 text-center" style={{padding: 15}}>
            <span className="labelFilter">Não há alunos nesta formação!</span>
          </div>
        }
        </div>
      </div>
    </div>
  </>
  )
 
}