import React, { useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import Api from '../services/Api'
import camera from '../assets/icons/camera.svg';

const ListItemMissa = ({ id, name, missa, missaDate, onDelete, loadMissas, setLoading }) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [missaName, setMissaName] = useState(name);
  const [stateMissa, setStateMissa] = useState(missa);

  // useMemo: executa quando determinado estado for alterado, neste caso o estado 'stateMissa'
  const preview = useMemo(() => {
    if(missa !== "" && missa === stateMissa){
      return Api.BASE_URL + '/files/'+ missa;
    } else {
      return stateMissa ? URL.createObjectURL(stateMissa) : null;
    }
  }, [stateMissa])
  
  function onChangeThumbnailFile(e){
    if(e.target.files[0]){
      let file_size = e.target.files[0].size;
      //let file_type = e.target.files[0].type;
      //Se maior que um 1mb(1048576)
      if(parseInt(file_size) >= 5240000) {
        toast.error("Arquivo não suportado! O arquivo deve ter no máximo 5MB", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }else{
        setStateMissa(e.target.files[0])
      }
    }
  };

  async function handleSubmit(e){
    e.preventDefault();
    setLoading(true);
    var fileMissa = stateMissa;
    //Cria um estrutura Multiform Data;
    const data = new FormData();
    data.append('id', id);
    data.append('name', missaName);
    if(typeof fileMissa === "object" && missa !== fileMissa.name){
      data.append('missa', missa);
      data.append('file', fileMissa);
    }
    try {
      const response = await Api.api.post('/store-missa', data);
      console.log(response);
      if(response.data.hasOwnProperty('_id')){
        toast.success("Operação realizada com sucesso!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setToggleItem([]);
        const reloadMissas = loadMissas;
        reloadMissas();
        setLoading(false);
        if(Number.isInteger(id)){
          console.log("Envia notificação");
          Api.sendNotification("Horários das Missas", 'Horários das missas de ' + missaName + ' disponíveis no app! Acesse e confira!', '*', '*');
        }
      } else {
        toast.error("Falha ao realizar a operação!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  return (
      <li className={toggleItem[0] === id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList"}>
        <div className="row headList" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>
          <div className="col-10">
            <span>{missaName}</span>
          </div>
          <div className="col-2 text-right">
            <span className={`iconItem ${toggleItem[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
          </div>
        </div>
        {toggleItem[0] === id ?
          <div className="row">
            <div className="col-12">
              <hr/>
              <form onSubmit={handleSubmit}>
                <div className="row v-center">
                  <div className="col-12">
                    <div className="row">
                      <div className="form-group col-12 col-md-7">
                        <label className="labelInput">Mês dos Horários:</label>
                        <input 
                          type="text" 
                          className="form-control" 
                          name="name"
                          placeholder="Mês dos Horários"
                          value={missaName}
                          onChange={event => setMissaName(event.target.value)}
                        />
                      </div>  
                      <div className="form-group col-12 col-md-5">
                        <label className="labelImage">Arquivo de Imagem:</label>
                          <label id="thumbnail" style={{ backgroundImage: `url(${preview})` }} className={stateMissa ? 'has-thumbnail' : 'text-center'}>
                            <input type="file" name="upload_files" onChange={onChangeThumbnailFile} />
                            <img src={camera} alt="Select Image" />
                          </label>
                        <br />
                      </div>
                    </div>     
                  </div>     
                  <div className="col-12 text-right">
                    <hr/>
                    <button type="button" className="btn btn-delete" onClick={onDelete}> Excluir </button>
                    <span> </span>
                    <button type="submit" className="btn btn-default">Salvar</button>
                  </div>
                </div>
              </form>
                
            </div>
          </div>
          : '' }
      </li>
  );
};

export default ListItemMissa;
