//useEffect = Método chamado ao carragar a página;
import React from 'react';
import { FaYoutube, FaCalendarDay, FaMusic, FaChurch, FaHome, FaSignOutAlt, FaRegPaperPlane, FaBookOpen, FaUserFriends } from "react-icons/fa";

//import { Link } from 'react-router-dom';
//import Api from '../../services/api'
import './styles.css';


export default function DashboardHome(props) {

  return (
    <div className="bodyDashboard">
      <div className="itensHome v-center">
        {/*<h3 className="title">Menus</h3>*/}
        <div className="col-12 col-md-6 text-center">
          <div className="itemHome" onClick={() => props.callbackParent(1)} >
            <FaBookOpen size="4em" className="itemHomeIcon"/>
            <div className="title">Catequese</div>
          </div>
        </div>
        <div className="col-12 col-md-6 text-center">
          <div className="itemHome" onClick={() => props.callbackParent(2)}>
            <FaCalendarDay size="4em" className="itemHomeIcon"/>
            <div className="title">Eventos</div>
          </div>
        </div>
        <div className="col-12 col-md-6 text-center">
          <div className="itemHome" onClick={() => props.callbackParent(7)}>
            <FaChurch size="4em" className="itemHomeIcon"/>
            <div className="title">Missas</div>
          </div>
        </div>
        <div className="col-12 col-md-6 text-center">
          <div className="itemHome" onClick={() => props.callbackParent(8)}>
            <FaUserFriends size="4em" className="itemHomeIcon"/>
            <div className="title">Usuários</div>
          </div>
        </div>
        <div className="col-12 col-md-6 text-center">
          <div className="itemHome" onClick={() => props.callbackParent(5)}>
            <FaMusic size="4em" className="itemHomeIcon"/>
            <div className="title">Áudios</div>
          </div>
        </div>
        <div className="col-12 col-md-6 text-center">
          <div className="itemHome" onClick={() => props.callbackParent(6)}>
            <FaYoutube size="4em" className="itemHomeIcon"/>
            <div className="title">Transmissão</div>
          </div>
        </div>
        {/*<h3 className="title">Apps</h3>
        <div className="col-12 col-md-6 text-center">
          <a href="https://play.google.com/store" target="_Blank">
            <div className="itemHome">
              <span className="fab fa-android fa-4x"></span>
              <div className="title">Android</div>
            </div>
          </a>
        </div>
        <div className="col-12 col-md-6 text-center">
          <a href="https://www.apple.com/br/" target="_Blank">
            <div className="itemHome">
                <span className="fab fa-apple fa-4x"></span>
                <div className="title">iOS</div>
            </div>
          </a>
        </div>*/}
      </div>
    </div>
  )
 
}