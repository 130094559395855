import React, { useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import Api from '../services/Api'
import camera from '../assets/icons/camera.svg';

const ListItemAudio = ({ id, name, audio, audioDate, onDelete, loadAudios, setLoading }) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [stateName, setStateName] = useState(name);
  const [stateAudio, setStateAudio] = useState(audio);

  const previewAudio = useMemo(() => {
    if(audio !== "" && audio === stateAudio){
      return(
        <audio src={Api.BASE_URL + '/files/'+ audio} style={{width: '60%'}} controls />
      )
    } else {
      return (
        <audio src={stateAudio ? URL.createObjectURL(stateAudio) : null} style={{width: '60%'}} controls />
      );
    }
  }, [stateAudio])

  function onChangeAudioFile(e){
    if(e.target.files[0]){
      let file_size = e.target.files[0].size;
      let file_type = e.target.files[0].type;
      if(file_type === "audio/mpeg"){
        if(parseInt(file_size) >= 52400000) {
          toast.error("Áudio não suportado! o tamanho do áudio não pode exceder o limite de 50MB!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else{
          setStateAudio(e.target.files[0]);
        }
      } else {
        toast.error("Formato do áudio não suportado! O formato do áudio deve ser .mp3!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  };

  async function handleSubmit(e){
    e.preventDefault();
    setLoading(true);
    //Cria um estrutura Multiform Data;
    const data = new FormData();
    data.append('id', id);
    data.append('name', stateName);
    if(typeof stateAudio === "object" && audio !== stateAudio.name){
      data.append('audio', audio);
      data.append('file', stateAudio);
    }
    try {
      const response = await Api.api.post('/store-audio', data);
      console.log(response);
      if(response.data.hasOwnProperty('_id')){
        toast.success("Operação realizada com sucesso!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setToggleItem([]);
        const reloadAudios = loadAudios;
        reloadAudios();
        setLoading(false);
        if(Number.isInteger(id)){
          console.log("Envia notificação");
          Api.sendNotification(stateName, "Novo áudio '" + stateName + "' disponível no app! Acesse e confira!", '*', '*');
        }
      } else {
        toast.error("Falha ao realizar a operação!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  return (
      <li className={toggleItem[0] === id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList"}>
        <div className="row headList" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>
          <div className="col-10">
            <span>{stateName}</span>
          </div>
          <div className="col-2 text-right">
            <span className={`iconItem ${toggleItem[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
          </div>
        </div>
        {toggleItem[0] === id ?
          <div className="row">
            <div className="col-12">
              <hr/>
              <form onSubmit={handleSubmit}>
                <div className="row v-center">
                  <div className="col-12">
                    <div className="row">
                      <div className="form-group col-12">
                        <label className="labelInput">Áudio:</label>
                        <input 
                          disabled
                          type="text" 
                          className="form-control" 
                          name="name"
                          placeholder="Nome do Áudio"
                          value={stateName}
                          onChange={event => setStateName(event.target.value)}
                        />
                      </div>  
                      <div className="form-group col-12">
                        <label>Arquivo de Áudio (.mp3):</label>
                        <div className="row">
                          {previewAudio}
                          <label htmlFor="audio-upload" className="btn btn-default" style={{marginTop: '-5px'}}>
                            Upload
                          </label>
                          <input 
                            type="file" 
                            accept=".mp3"
                            id="audio-upload"
                            name="upload_files"
                            onChange={onChangeAudioFile}
                          />
                        </div>
                        <br />
                      </div>
                    </div>     
                  </div>     
                  <div className="col-12 text-right">
                    <hr/>
                    {/*<button type="button" className="btn btn-delete" onClick={onDelete}> Excluir </button>*/}
                    <span> </span>
                    <button type="submit" className="btn btn-default">Salvar</button>
                  </div>
                </div>
              </form>
                
            </div>
          </div>
          : '' }
      </li>
  );
};

export default ListItemAudio;
