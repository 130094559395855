import axios from 'axios';

//var BASE_API = 'http://localhost:3000/api';
var BASE_API = 'https://server-f56sdgkdma-rj.a.run.app/api';
var BASE_URL = 'https://storage.googleapis.com/igrejatm-files';

const api = axios.create({
  baseURL: BASE_API
});

// CADASTRO E LOGIN
const registerUser = async (name, email, telefone, dataNascimento, pass, catequeseList) => {
  try {
    const response = await api.post('/store-user', {
      "name": name,
      "email": email,
      "telefone": telefone,
      "dataNascimento": dataNascimento,
      "password": pass,
      "catequeseList": catequeseList,
      "active": false
    });
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const login = async (email, pass) => {
  if(email && pass){
    try {
      const response = await api.post('/login/', {
        "email": email,
        "senha": pass
      });
      if(response){
        return response.data;
      } else {
        return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
      }
    } catch (error) {
      console.log(error);
      return {message: error.errmsg, success: false};
    }
  } else {
    return {message: "Por favor, preencha todos os campos!", success: false};
  }
}
const getUser = async (user_id) => {
  try {
    const response = await api.get(`/get-users/${user_id}`);
    if(response){
      return response.data[0];
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const getUsers = async () => {
  try {
    const response = await api.get(`/get-users/`);
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const loadUserLogged = async () => {
  try {
    const user_id = await AsyncStorage.getItem('user') || "";
    const response = await api.get(`/get-users/${user_id}`);
    if(response){
      return response.data[0];
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}

const sendNotification = async (title, body, filter, token) => {
  const response = await api.post('/send-notification', {
          title,
          body,
          filter,
          token
      }
  );
  return response;
}

const loadDataAtual = async () => {
  const response = await api.get('/data-hora-atual');
  return response.data.dataHoraAtual;
}

// Eventos
const getEvents = async () => {
  const response = await api.get(`/get-events`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
  //return response.data;
  /*const req = await fetch(`${BASE_API}/get-events`);
  const json = await req.json();
  return json;*/
}
const getEvent = async (id) => {
  const response = await api.get(`/get-events/${id}`);
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
// Catequese
const getCatequeses = async () => {
  const response = await api.get(`/get-catequese`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getCatequese = async (id) => {
  const response = await api.get(`/get-catequese/${id}`);
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getAlunosCatequese = async (idCatequese) => {
  const response = await api.get(`/get-alunos-catequese/${idCatequese}`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
// Aulas
const getAulas = async () => {
  const response = await api.get(`/get-aulas`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getAula = async (id) => {
  const response = await api.get(`/get-aulas/${id}`);
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getAulasCatequese = async (idCatequese) => {
  const response = await api.get(`/get-aulas-catequese/${idCatequese}`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

// Áudios
const getAudios = async () => {
  const response = await api.get(`/get-audios`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getAudio = async (id) => {
  const response = await api.get(`/get-audio/${id}`);
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

// Links
const getLinks = async () => {
  const response = await api.get(`/get-links`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getLink = async (id) => {
  const response = await api.get(`/get-links/${id}`);
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const updateLink = async (id, linkYoutube, linkMissas) => {
  var data = {};
  if(linkYoutube !== ""){
    data = {
      id, 
      linkYoutube
    }
  } else if(linkMissas !== ""){
    data = {
      id, 
      linkMissas
    }
  }
  const response = await api.post(`/store-link`, data);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

// Missas
const getMissas = async () => {
  const response = await api.get(`/get-missas`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getMissa = async (id) => {
  const response = await api.get(`/get-missa/${id}`);
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

// Promoções
const getPromotions = async () => {
  const response = await api.get(`/get-promotions`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getPromotion = async (id) => {
  const response = await api.get(`/get-promotions/${id}`);
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

const saveItem = async (route, data) => {
  try {
    const response = await api.post(route, data);
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}

const deleteItem = async (route, id) => {
  try {
    const response = await api.post(route, { id });
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}

export default {
  api,
  BASE_API,
  BASE_URL,
  registerUser,
  login,
  loadUserLogged,
  getUsers,
  getUser,
  sendNotification,
  loadDataAtual,
  getEvents,
  getEvent,
  getCatequeses,
  getCatequese,
  getAlunosCatequese,
  getAulas,
  getAula,
  getAulasCatequese,
  getAudios,
  getAudio,
  getLinks,
  getLink,
  updateLink,
  getMissas,
  getMissa,
  getPromotion,
  getPromotions,
  saveItem,
  deleteItem
};